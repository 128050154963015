module.exports = [{
      plugin: require('../node_modules/gatsby-source-google-docs/gatsby-browser.js'),
      options: {"plugins":[],"folder":"1rLwZdOpl3WxkNNuKPbO1s2YR9Ew7jZRf","createPages":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[],"preset":{"default":{"space":[0,8,16,32,64],"radii":[0,10,20],"breakpoints":["500px","800px","1000px"],"fontSizes":[12,14,16,18,24,28,32],"colors":{"text":"#333333","background":"#F9DFC9","primary":"#6F2B9F","secondary":"#4d90fe","modes":{"dark":{}}},"textStyles":{"heading":{"m":0,"mb":1,"color":"black"}},"styles":{"root":{"fontFamily":"Quicksand","scrollbarGutter":"stable both-edges"},"a":{"color":"secondary","textDecoration":"none","fontWeight":"bold"},"h1":{"variant":"textStyles.heading","fontSize":6},"h2":{"variant":"textStyles.heading","fontSize":5},"h3":{"variant":"textStyles.heading","fontSize":4},"h4":{"variant":"textStyles.heading","fontSize":3},"h5":{"variant":"textStyles.heading","fontSize":2},"h6":{"variant":"textStyles.heading","fontSize":1}}}}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
